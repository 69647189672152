var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('RefundsListFilters',{on:{"on:filter":_vm.handleFilter}}),(_vm.isLoadingRefunds)?[_c('b-skeleton',{staticClass:"mt-1",attrs:{"animation":"wave","width":"100%","height":"430px"}})]:[_c('b-card',{staticClass:"mt-1 border border-dark shadow-none",attrs:{"no-body":""}},[_c('b-card-text',[(_vm.availableRefunds)?_c('b-table',{staticClass:"my-0",attrs:{"items":_vm.refunds,"fields":_vm.columns,"responsive":""},scopedSlots:_vm._u([{key:"cell(No)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.indexFrom + data.index)+" ")]}},{key:"cell(ID)",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'refund-details', params: { id: item.IdDevolution } }}},[_vm._v(" "+_vm._s(item.ID)+" ")])]}},{key:"cell(OrderID)",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.OrderID))])]}},{key:"cell(TotalMxn)",fn:function(ref){
var item = ref.item;
return [( item.TotalMxn)?_c('span',[_vm._v(" "+_vm._s(_vm._f("currency")(item.TotalMxn))+" ")]):_c('span',{staticClass:"text-nowrap"},[_vm._v("Sin especificar")])]}},{key:"cell(created_at)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.created_at,"DD/MM/YYYY hh:mm a"))+" ")]}},{key:"cell(status)",fn:function(ref){
var item = ref.item;
return [(item.status)?[_c('div',{staticClass:"text-nowrap",class:['sm-banner-alert', _vm.statusColorStyle(item.status)],staticStyle:{"display":"inline","font-size":"11px"},attrs:{"role":"alert"}},[_vm._v(" "+_vm._s(item.status)+" ")])]:_c('span',{staticClass:"text-nowrap"},[_vm._v("Sin especificar")])]}},{key:"cell(received_month)",fn:function(ref){
var item = ref.item;
return [(item.received_at)?_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(_vm._f("capitalize")(_vm._f("moment")(item.received_at,"MMMM YYYY"))))]):_c('span',{staticClass:"text-nowrap"},[_vm._v("Sin especificar")])]}},{key:"cell(received_date)",fn:function(ref){
var item = ref.item;
return [(item.received_at)?_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(_vm._f("moment")(item.received_at,"DD/MM/YYYY")))]):_c('span',{staticClass:"text-nowrap"},[_vm._v("Sin especificar")])]}},{key:"cell(RefundPayment)",fn:function(ref){
var item = ref.item;
return [(item.RefundPayment)?[_c('div',{staticClass:"text-nowrap",class:['sm-banner-alert', _vm.statusColorStyle(item.RefundPayment)],staticStyle:{"display":"inline","font-size":"11px"},attrs:{"role":"alert"}},[_vm._v(" "+_vm._s(item.RefundPayment)+" ")])]:_c('span',{staticClass:"text-nowrap"},[_vm._v("Sin especificar")])]}},{key:"cell(CommissionDiscount)",fn:function(ref){
var item = ref.item;
return [(_vm.DISCOUNT_TO_COMMISSION_STATUS.includes(item.CommissionDiscount))?[_c('div',{staticClass:"text-nowrap ml-0",class:['sm-banner-alert', _vm.statusColorStyle(item.CommissionDiscount)],staticStyle:{"display":"inline","font-size":"11px"},attrs:{"role":"alert"}},[_vm._v(" "+_vm._s(item.CommissionDiscount)+" ")])]:_c('span',{staticClass:"text-nowrap"},[_vm._v("Por definir")])]}}],null,false,3492000223)}):_vm._e(),(!_vm.availableRefunds && !_vm.isLoadingRefunds)?_c('b-alert',{staticClass:"my-0",attrs:{"variant":"warning","show":""}},[_c('div',{staticClass:"alert-body"},[_c('span',[_vm._v("No se encontraron devoluciones.")])])]):_vm._e()],1)],1)],(_vm.availableRefunds)?_c('BasicPaginator',{ref:"basic-paginator",staticClass:"mt-2",attrs:{"callback":_vm.handleChangePagination,"total-rows":_vm.totalRefunds}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }